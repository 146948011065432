import React from "react";
import loadable from "@loadable/component";
import { CAREERS_IMAGES_COLLAGE_IMAGE_CONTAINER, CAREERS_IMAGES_COLLAGE_IMAGE_CLASSNAME } from "../../../../constants";

const Image = loadable(() => import("@common/Image/Image"));

const Mobile = ({ images: { src, alt } }) => {
  return (
    <div className={CAREERS_IMAGES_COLLAGE_IMAGE_CONTAINER}>
      <Image
        src={src}
        className={CAREERS_IMAGES_COLLAGE_IMAGE_CLASSNAME}
        alt={alt}
      />
    </div>
  )
}

export default Mobile;
